import React, {memo} from 'react';
import styled from 'styled-components';
import {Trans} from 'gatsby-plugin-react-i18next';

import PageWrapper from '../containers/PageWrapper';

const PrivacyPolicy = () => (
  <PageWrapper title="Privacy Policy" description="" urlPath="privacy-policy" keywords={['']}>
    <Container>
        <h1><Trans>Privacy Policy</Trans></h1>

      <h2><Trans>General information 1</Trans></h2>
      <P>
        <Trans>General information 1 desc</Trans>
      </P>

      <h2><Trans>General information 2</Trans></h2>
      <P>
          <Trans>General information 2 desc</Trans>
      </P>

     <h2><Trans>General information 3</Trans></h2>
     <P>
         <Trans>General information 3 desc</Trans>
     </P>

    <h2><Trans>General information 4</Trans></h2>
    <P>
        <Trans>General information 4 desc</Trans>
    </P>

    <h2><Trans>General information 5</Trans></h2>
    <P>
        <Trans>General information 5 desc</Trans>
    </P>

    <h2><Trans>General information 6</Trans></h2>
    <P>
        <Trans>General information 6 desc</Trans>
    </P>

    </Container>
  </PageWrapper>
);

const Container = styled.div`
  width: 100%;
  padding: 0 2rem;
  padding-bottom: 1rem;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
`;

const P = styled.p`
  text-align: justify;
`;

export default memo(PrivacyPolicy);
